<template>
  <div class="product">
    <van-nav-bar
      title="化验列表"
      left-text="返回"
      right-text="筛选"
      left-arrow   
      @click-left="toHome" 
      @click-right="chooseshow = true" 
    />
    <van-row class="mt10">
      <van-col span='1'></van-col>
      <van-col span="">{{params.start_time+'~'+params.end_time}}</van-col>     
    </van-row>
    <van-cell-group class="mt10">
      <div id="">
        <ve-table id="" :columns="columns" :table-data="tableData" :border-x="true" scrollWidth="1200px" :border-y="true" /> 
      </div>      
    </van-cell-group>
    <!-- <ve-pagination class="mt10" v-if="page.show"
      :layout = "page.layout"
      :total = "page.total"
      :page-size = "page.offset"
      :paging-count = "page.paging"
      
      @on-page-number-change = "pageChange"/> -->
    <van-pagination class="mt10" v-model="page.currentPage" :total-items="page.total" :items-per-page='15' force-ellipses v-if="page.show" @change='pageChange'>
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
      <template #page="{ text }" force-ellipses>{{ text }}</template>
    </van-pagination>

    <van-popup round v-model="chooseshow" position="top">
      <van-field
        readonly
        clickable
        name="start_time"
        :value="params.start_time"
        label="开始时间"
        placeholder="点击选择时间"
        @click-input="stimeShow = true"
      />
      <van-field
        v-model="params.end_time"
        name="end_time"
        label="结束时间"
        @click-input="etimeShow = true"
        readonly />
        <van-field
          readonly
          v-model="params.sample_name"
          placeholder="输入样品关键词"
          name="sample_name"
          label="样品名称"  @click-input="showSample"/>    
          <van-field v-model.trim="params.inspect_code"
          name="inspect_code"
          placeholder="输入报检编号"
          label="报检编号" 
          clearable />     
        <van-field
          readonly
          clickable
          name="inspect_type"
          :value="params.type_name"
          label="报检类型"
          placeholder="点击选择报检类型"
          @click-input="showType"
        />
        <van-field
          readonly
          clickable
          name="inspect_dep_id"
          :value="params.inspect_dep_name"
          label="报检部门"
          placeholder="点击选择报检部门"
          @click-input="showDep"
        />
        <!-- <van-field
          readonly
          clickable
          name="equipment_id"
          :value="params.equipment_name"
          label="检测设备"
          placeholder="点击选择检测设备"
          @click-input="showEqu"
        /> -->
        <van-field
          readonly
          v-model="params.supplier_name"
          placeholder="输入供应商关键词"
          name="supplier_name"
          label="供应商"  @click-input="showSupplier"/>
        <van-field
          readonly
          clickable
          name="conclusion"
          :value="params.conclusion_val"
          label="检测结果"
          placeholder="点击选择检测结果"
          @click-input="showHg"
        />                        
      <van-row style="margin: 16px;" gutter="20">  
        <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="clickRest()">重置</van-button></van-col>
        <van-col span="16"><van-button title="筛选" round block size="small" type="primary" @click="clickBtn()">确定</van-button></van-col>      
        <!-- <van-col span='10'>
          <van-button round block type="default" @click="clickRest()">重置</van-button>
        </van-col>
        <van-col span='4'></van-col>       
        <van-col span='10'>
          <van-button round block type="info" @click="clickBtn()">确定</van-button>
        </van-col> -->
      </van-row>  
    </van-popup>    
    <!--开始年月日  --> 
    <van-popup v-model="stimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="stimeConfirm"
        @cancel="stimeShow = false"
      />
  </van-popup>
  <!-- 结束时间 -->
  <van-popup v-model="etimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="etimeConfirm"
        @cancel="etimeShow = false"
      />
  </van-popup>
  <!-- 产品搜索 -->
  <van-popup v-model="sampleShow" position="bottom" round :style="{ height: '90%' }">
    <van-search v-model="params.sample_name" placeholder="请输入报检产品关键词" @input="searchSample" :autofocus="autofocus" />
    <van-cell v-for="(item, idx) in productList" :key="idx" :title="item.sample_name + ' ' + item.sample_specs" @click="toProduct(item.sample_name)"/>
  </van-popup>
  <!-- 报检类型 -->
  <van-popup v-model="typeShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="type_name"
      :columns="types"
      @confirm="typeConfirm"
      @cancel="typeShow = false"
    />
  </van-popup>
  <!-- 报检部门 -->
  <van-popup v-model="depShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="dd_dep_name"
      :columns="depTypes"
      @confirm="depConfirm"
      @cancel="typeShow = false"
    />
  </van-popup>  
  <!-- 报检设备 -->
  <!-- <van-popup v-model="equShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="equipment_name"
      :columns="equTypes"
      @confirm="equConfirm"
      @cancel="equShow = false"
    />
  </van-popup> -->

  <!-- 供应商搜索 -->
  <van-popup v-model="supplierShow" position="bottom" round :style="{ height: '90%' }">
    <van-search v-model="params.supplier_name" placeholder="请输入供应商关键词" @input="searchSupplier" :autofocus="autofocus" />
    <van-cell v-for="(item, idx) in supplierList" :key="idx" :title="item.supplier_name" @click="toSupplier(item.supplier_code,item.supplier_name)"/>
  </van-popup>
  <!-- 检测结果 -->
  <van-popup v-model="hgShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="val"
      :columns="hgList"
      @confirm="hgConfirm"
      @cancel="hgShow = false"
    />
  </van-popup>
   
  <div class="import-excel" v-if="isPhone == false" title="导出excel" @click="ToExcel" v-track="{triggerType:'click', title:'导出检测台账',currentUrl: $route.path,behavior: '导出检测台账', duration: new Date().getTime()}">            
    <van-icon  name="down" color="#fff" size="26" style="line-height: 50px;" />
  </div>                                  
</div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog,NavBar,DatetimePicker,Picker,Image as VanImage, Search,Uploader,ImagePreview,Icon,Pagination   } from 'vant';
import { mapActions } from 'vuex'
import exportTableUtil from '@/utils/exportTableUtil.js'
import XLSX from 'xlsx'
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(NavBar)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Search)
Vue.use(VanImage)
Vue.use(Uploader)
Vue.use(ImagePreview)
Vue.use(Icon)
Vue.use(Pagination)
export default {  
name: 'DataDepartments',
components: {},
data() {    
  return {      
    isPhone: this.$common.isMobilePhone(),    
    chooseshow: false, 
    stimeShow: false,
    etimeShow: false,
    searchValue: '',
    typeList: [],      
    loading: false,
    finished: false, 
    sampleShow: false,
    supplierShow: false,
    typeShow: false,
    depShow: false,
    equShow: false,
    hgShow: false, 
    fjShow: false,
    imgShow: false,
    supplierList: [], //供应商
    productList: [],
    sampleList: [], //样品列表
    types: [], //报检类型
    depTypes: [], //报检部门
    equTypes: [], //设备列表
    cjList: [], //用户列表
    attachList: [], //附件列表
    autofocus: false,
    hgList: [{val:'合格',val_id: '1'},{val:'不合格',val_id:'0'}],
    checkList: [{cheName: '待检测',cheId:'0'},{cheName: '检测中',cheId:'1'},{cheName: '初检完成',cheId:'2'},{cheName: '检测完成',cheId:'3'},{cheName: '让步审批中',cheId:'4'},{cheName: '审批完成',cheId:'5'}],
    dataList:{
        check_items:[],
        inspect:{
          type: { }
        }
      },
    page: {
      show: false,
      currentPage: 1,
      total: 0,
      page: 1,
      offset: 15,
    },
    tableData: [], 
    tableAll: [],
    columns: [   
      { field: "check_time", key: "7", title: "化验时间", align: "center",width: "180px" },
      // { field: "inspect_code", key: "0", title: "报检编号", align: "center",width: "160px"},
      { field: "sample_name", key: "1", title: "产品名称", align: "center",width: "200px"},
      { field: "inspect_dep_name", key: "2", title: "报检部门", align: "center",width: "100px"},
      { field: "type_name", key: "3", title: "报检类型", align: "center",width: "160px"},  
      { field: "inspect_batch", key: "4", title: "报检批次", align: "center",width: "200px"}, 
      // { field: "erp_purchase_arrival_no", key: "4", title: "ERP到货单号", align: "center",width: "220px"},                 
      // { field: "supplier_name", key: "4", title: "供应商", align: "center",width: "200px"},      
      { field: "is_concession_acceptance", key: "5", title: "是否让步", align: "center",width: "80px"},      
      { field: "conclusion", key: "6", title: "结 果", align: "center" },      
      { field: "fujian", key: "8", title: "查看详情", align: "center",
        renderBodyCell: ({ row, column, rowIndex }, h) => {          
          return ( <span>                
                <button class="button-demo" on-click={() => this.getDetail(row)}>详 情</button>                  
            </span>
            ); 
        }
      }],    
    //   <button class="button-demo" v-print="print" on-click={() => this.editRow(row)} >打 印</button>
    params:{      
      check_id: '',
      start_time: this.$common.formatDate(new Date(2022, 0, 1)),
      end_time: this.$common.formatDate(new Date()),
      sample_name: '',
      supplier_name: '',
      supplier_code: '',
      inspect_type: '',
      type_name: '',
      inspect_dep_id: '',
      inspect_dep_name: '',
      inspect_emp_id: '',
      inspect_emp_name: '',
      conclusion: '',
      check_emp_id: '',
      check_emp_name: '',
      recheck_emp_id: '',
      supplier_code: '',
      check_status: JSON.stringify(['3','5']),
      inspect_code: ''                              
    },
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
    };    
  },
  created: function () {  
    this.onLoad()
    this.getTypes() 
  },   
  mounted() {   
  },
  methods: {   
    ...mapActions('listData', ['asyncsetSampleType']),       
    onLoad () {
      var that = this                                  
      this.$get('check/get-check-list-only', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        var dataList = []
        const data = res.data.list        
        data.forEach(ele => {   
          ele.erp_purchase_arrival_no = ele.inspect.erp_purchase_arrival_no 
          ele.inspect_code = ele.inspect.inspect_code
          ele.inspect_dep_name = ele.inspect.inspect_dep_name
          ele.sample_name = ele.inspect.sample_name
          ele.inspect_batch = ele.inspect.inspect_batch
          ele.check_time = this.$common.initDate(ele.check_time)  
          if(ele.conclusion == '0') {
            ele.conclusion = '不合格'
          } else {
            ele.conclusion = '合格'
          } 
          if(ele.check_status == '3' || ele.check_status == '5') {
            dataList .push(ele)
          } 
          if(ele.is_concession_acceptance == '0') {
            ele.is_concession_acceptance = '否'
          } else {
            ele.is_concession_acceptance = '是'
          }
          ele.type_name = ele.inspect.type.type_name   
          ele.supplier_name = ele.inspect.supplier_name            
        })        
        this.tableData = dataList
        this.page.total = Number(res.data.count)
        if (this.page.total > this.page.offset) {
          this.page.show = true
        }
      }).catch(err => {
        this.tableData = []
        this.page.total = 0
        this.$notify({ type: 'warning', message: err.message })
      })      
    }, 
    // 获取详情
    getDetail(item) {      
        this.$router.push({
            path: '/analysisdetail',
            query: {
                id: item.id,
                toFlag: true
            }
        })
    },
    pageChange(val) {         
      this.page.page = val
      this.onLoad()
    },    
     // 获取报检类型
    getTypes() {
      const that = this
      let SampleType = this.$store.state.listData.SampleType
      if (SampleType.length === 0) {
        that.$get('sample-type/get-sample-type-list', 'api', {
        page: 1,
        offset: 100,
        }).then(res => {
          const datas = res.data.list
          that.types = datas
          that.asyncsetSampleType(datas)
        }, err => {
          console.log(err)
          this.$notify({ type: 'warning', message: err.message })
        })
      } else {
        that.types = SampleType
      }
      this.typeStatus = true
    },
     showSample() { 
      this.autofocus = true        
      this.sampleShow = true
      this.searchSample(this.params.sample_name)
    },
    showSupplier() {
      this.autofocus = true
      this.supplierShow = true
      this.searchSupplier(this.params.supplier_name)
    },
    // 报检类型
    showType() {
      this.typeShow = true
      this.getTypes()
    },
    // 报检部门
    showDep() {
      this.depShow = true
      this.getDep()
    },  
    // 检测设备
    showEqu() {
      this.equShow = true
      this.getEqu()
    },
    // 检测结果
    showHg() {
      this.hgShow = true      
    },

    // 获取产品名
    searchSample(val) {
      this.$get('inspect/get-sample-list','api', {page: 1,offset: 10,sample_name: val}).then(res => {   
        var data = res.data.list 
        this.productList = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 供应商名
    searchSupplier(val) {
      this.$get('inspect/get-supplier-list','api', {page: 1,offset: 10,supplier_name: val}).then(res => {   
        var data = res.data.list 
        this.supplierList = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取报检部门
    getDep() {
      this.$get('inspect/get-inspect-dep-list','api', {}).then(res => {   
        var data = res.data.list 
        this.depTypes = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取检测设备
    getEqu () {
      this.$get('equipment/get-equipment-list','api', {page:1,offset: 100, equipment_name: ''}).then(res => {   
        var data = res.data.list 
        // console.log(data)
        data.forEach(ele => {
          ele.equipment_name = ele.equipment_name+'-'+ele.qc_code
        })
        this.equTypes = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取用户信息
    getUser(isRecheck,isCheck) {
      var params = {
        page: 1,
        offset: 100,
        username: '',
        is_accept_auth: '',
        is_recheck_auth: isRecheck,
        is_check_auth: isCheck
      }      
      this.$get('user/get-user-list','api', params).then(res => {   
        var data = res.data.data 
        // console.log(data)      
        this.cjList = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 开始日期确认
    stimeConfirm(time) {    
      this.params.start_time = this.$common.formatDate(time)
      this.stimeShow = false;
    },
    // 结束日期确认
    etimeConfirm(time) {    
      this.params.end_time = this.$common.formatDate(time)
      this.etimeShow = false;      
    },
    // 样品名称确认
    toProduct(val) {
      this.params.sample_name = val
      this.sampleShow = false      
    },
    // 供应商确认
    toSupplier(code,name) {    
      this.params.supplier_code = code
      this.params.supplier_name = name
      this.supplierShow = false
    },
    // 报检类型确认
    typeConfirm(value) {
      // console.log(value)
      this.params.type_name = value.type_name
      this.params.inspect_type = value.id
      this.typeShow = false
    },
    // 报检部门确认
    depConfirm(depVal) {      
      this.params.inspect_dep_name = depVal.dd_dep_name
      this.params.inspect_dep_id = depVal.dd_dep_id
      this.depShow = false
    },
    // 报检设备确认
    equConfirm(epuVal) {
      this.params.equipment_name = epuVal.equipment_name
      this.params.equipment_id = epuVal.id
      this.equShow = false
    },
    // 报检结果确认
    hgConfirm(hgVal) {
      this.params.conclusion_val = hgVal.val
      this.params.conclusion = hgVal.val_id
      this.hgShow = false
    },

    // 点击确定
    clickBtn() {      
      this.sampleList = []
      this.page.page = 1  
      this.page.currentPage = 1      
      this.pageChange(1)
      this.chooseshow = false
    },   
    // 点击重置
    clickRest () {
      this.params = {
        start_time: this.$common.formatDate(new Date(2022, 0, 1)),
        end_time: this.$common.formatDate(new Date()),
        sample_name: '',
        supplier_code: '',
        supplier_name: '',
        inspect_type: '',
        inspect_dep_id: '',
        inspect_emp_id: '',
        conclusion: '',
        is_inspect_recheck: '',
        equipment_id: '',
        check_status: JSON.stringify(['3','5']),
        is_concession_acceptance: '',
        check_emp_id: '',
        recheck_emp_id: '',
        page: 1,
        offset: 10,         
      }
      this.onLoad()
    },   
    // 获取附件信息
    getFjList(id) {        
      this.$post('statistics/get-sample-attach', 'api', { id: id }).then(res => {
          const datas = res.data.attach                    
          this.attachList = datas
          this.imgShow = true
          ImagePreview({
            images: this.attachList,
            showIndex: true,
            loop: true,
            closeable: true       
          })
      }, err => {
          console.log(err)
          this.imgShow = false
          this.attachList = []            
      })
    },
    // 查看附件
    editRow(val) {           
      this.getFjList(val.id)
    },
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    },     
    // 导出excel
    ToExcel () {
      let tbody = document.querySelector('#table tbody')
      var tr = tbody.getElementsByTagName('tr')
      tr.forEach(ele => {
        if(ele.className.indexOf('ve-table-body-tr') == -1){  
           tbody.removeChild(ele)         
        }
      })    
      let wb = XLSX.utils.table_to_book(document.querySelector('#table'))
      let elt = document.getElementById('exportData')      
      exportTableUtil.exportTable(elt, '检测台账', '检测台账', 1)
    }
  }          
};
</script>
<style scoped>
/* .product .van-list {
  margin-top: 66px;
} */
 .status{
  position: absolute;
  top: 30px;
  right: 0px;
  width: 80px;
  height: 80px;
  /* opacity: 40%; */
  /* transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg); */
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: wheat;
   position: fixed;
   bottom: 100px;
   z-index: 99999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
 .red {
   color: red;
 }
 .van-cell-group--inset {
    height: 300px;
    overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 9999;
}
.van-tag {
  border-radius: 16px;
}
.import-excel {
   width: 50px;
   height: 50px;
   background: #3d7cf3;
   position: fixed;
   bottom: 150px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
}
#exportData .ve-table-body{
  display: none;
}

.tac {
  text-align: center;
}
.status{
  position: absolute;
  top: -30px;
  right: 0px;
  width: 80px;
  height: 80px;
  z-index: 98;
 }
.sampledetail{
  padding-bottom: 60px;
  padding-top: 10px;
}
.title{
  font-size: 14px;
  color: #565656;
  margin: 10px 16px;
}
.van-step__icon h6, 
.van-step__title h6{
  margin: 0;
}
.title+p.gray{
  background-color: #fff;
}
.btn-r{
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
  font-size: 12px;
  color: #000000d9;
}
.table-cle td {
  padding: 6px 2px;
  text-align: center;
  border-right-color: #000; 
}
#printMe {
  height: 0; 
  overflow: hidden;
  padding: 0 10px !important;
}
@media print {
  #printMe {
    height: auto;
    display: block !important;
  }
  .item{
    display: block !important;
  }
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th,#printMe >>> .ve-table .ve-table-container .ve-table-border-y td {
  border-right: 1px solid #000 !important;
  border-bottom-color: #000 !important;
  font-size: 12px;
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th:last-child, #printMe >>> .ve-table-body tr:nth-child(2) td:last-child{
  border-right: none !important;
}
#printMe >>> .ve-table-body tr:nth-child(2) td:last-child{
  border-bottom: none !important;
}
#printMe >>> .ve-table .ve-table-container .ve-table-border-y th {
  border-bottom-color: #000 !important;
}
#printMe >>> .ve-table-content .ve-table-body td {
  border-color: #000 !important;
}
#printMe .van-cell__value, #printMe .van-cell {
  color: #000000d9;
}
#printMe .van-cell__title {
  text-align: right;
}
#printMe .van-cell__value {
  text-align: left;
}
/*去除页眉页脚*/
  @page{
    size:  auto;   /* auto is the initial value */
    margin: 3mm;  /* this affects the margin in the printer settings */
  }
 
  html{
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }
</style>